export const imagePlaceholderDefault =
  // eslint-disable-next-line max-len
  "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAwIiBoZWlnaHQ9IjMwMCIgdmlld0JveD0iMCAwIDMwMCAzMDAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHJlY3Qgd2lkdGg9IjMwMCIgaGVpZ2h0PSIzMDAiIGZpbGw9IiNFM0UzRTMiLz48L3N2Zz4=";

export const noImage = {
  src: "/assets/images/no-image.svg",
  alt: "No Image",
  blurDataURL: imagePlaceholderDefault,
};

export const imageDisabled = {
  src: "/assets/images/stop.svg",
  alt: "No Image",
  blurDataURL: imagePlaceholderDefault,
};
