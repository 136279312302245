"use client";

// Core
import cx from "classnames";
import { useTranslation } from "react-i18next";

// Components
import { Grid } from "@/client/components/ui/Grid";
import { Image } from "@/client/components/ui/Image";
import { Link } from "@/client/components/others/link";

// Styles
import st from "./styles.module.css";

// Utils
import { routes } from "@/client/definitions/book";

export const logoType = {
  light: "light",
  dark: "dark",
} as const;
export const logoSrcList: Record<keyof typeof logoType, `/assets/images/${string}`> = {
  light: "/assets/images/pro-logo-light.svg",
  dark: "/assets/images/pro-logo-dark.svg",
};
export const logoSrcMobileList: Record<keyof typeof logoType, `/assets/images/${string}`> = {
  light: "/assets/images/pro-logo-light.svg",
  dark: "/assets/images/pro-logo-dark.svg",
};
export type LogoProps = {
  alt?: string;
  width?: number;
  height?: number;
  type?: keyof typeof logoType;
  testId?: string;
};

export const Logo = (props: LogoProps) => {
  const { t } = useTranslation(["common"]);
  const { alt = t("logoAlt"), width = 250, height = 26, type = logoType.light, testId } = props;
  const { lg = true, md = true } = Grid.useBreakpoint();

  const logoByType = logoType[type] || logoType.light;
  const logoSrc = md
    ? logoSrcList[logoByType] || logoSrcList.light
    : logoSrcMobileList[logoByType] || logoSrcList.light;

  const logoStyle = cx(st.logo, {
    [st.mobile]: !md,
    [st.tablet]: !lg,
  });

  return (
    <div className={logoStyle} data-testid={testId}>
      <Link href={routes.home} shallow={false} className={st.box}>
        <Image src={logoSrc} width={width} height={height} alt={alt} unoptimized priority />
      </Link>
    </div>
  );
};
