import { ValueOf } from "@/common/types/basic";

export const scrollTo = (element: string) => {
  import("react-scroll/modules/mixins/scroller")
    .then(({ default: scroller }) => {
      return scroller.scrollTo(element, {
        duration: 500,
        delay: 500,
        smooth: true,
      });
    })
    .catch((err) => {
      throw err;
    });
};

export const SCROLL_NAMES = {
  header: "header",
  footer: "footer",
} as const;

export type ScrollNamesType = ValueOf<typeof SCROLL_NAMES>;
